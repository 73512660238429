import {
    SomethingWentWrong as SourceSomethingWentWrong
} from 'SourceRoute/SomethingWentWrong/SomethingWentWrong.component';

import './SomethingWentWrong.geely.style';

/** @namespace Geely/Route/SomethingWentWrong/Component */
export class SomethingWentWrongComponent extends SourceSomethingWentWrong {}

export default SomethingWentWrongComponent;
