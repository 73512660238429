import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { TOOLTIP_POPUP_PREFIX } from 'Component/ToolTipButton/ToolTipButton.config';
import {
    mapDispatchToProps,
    mapStateToProps as jaidahMapStateToProps,
    ToolTipButtonContainer as JaidahToolTipButtonContainer
} from 'JaidahComponent/ToolTipButton/ToolTipButton.container';

export {
    mapDispatchToProps
};

/** @namespace Geely/Component/ToolTipButton/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...jaidahMapStateToProps(state),
    activeOverlay: state.OverlayReducer.activeOverlay
});

/** @namespace Geely/Component/ToolTipButton/Container */
export class ToolTipButtonContainer extends JaidahToolTipButtonContainer {
    static propTypes = {
        ...this.propTypes,
        skipUnfreeze: PropTypes.bool
    };

    static defaultProps = {
        ...this.defaultProps,
        skipUnfreeze: false
    };

    state = {
        ...this.state,
        isForceVisible: false
    };

    containerProps() {
        const { popupOpenDirection, skipUnfreeze } = this.props;
        const { isForceVisible } = this.state;

        return {
            ...super.containerProps(),
            popupOpenDirection,
            isForceVisible,
            skipUnfreeze
        };
    }

    showTooltip() {
        const {
            id,
            showToolTipPopup,
            activeOverlay
        } = this.props;
        const tooltipPopupName = `${TOOLTIP_POPUP_PREFIX}-${id}`;

        if (activeOverlay !== tooltipPopupName) {
            this.setState({ isForceVisible: true });
        } else {
            showToolTipPopup(tooltipPopupName);
        }

        this.setActive(true);
    }

    hideTooltip() {
        const {
            hideActiveOverlay,
            hideActivePopup,
            activeOverlay,
            id
        } = this.props;
        const tooltipPopupName = `${TOOLTIP_POPUP_PREFIX}-${id}`;

        if (activeOverlay !== tooltipPopupName) {
            this.setState({ isForceVisible: false });
        } else {
            hideActiveOverlay();
            hideActivePopup();
        }

        this.setActive(false);
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ToolTipButtonContainer);
