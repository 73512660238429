import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    ToolTipPopupContainer as JaidahToolTipPopupContainer
} from 'JaidahComponent/ToolTipPopup/ToolTipPopup.container';

export {
    mapDispatchToProps,
    mapStateToProps
};

/** @namespace Geely/Component/ToolTipPopup/Container */
export class ToolTipPopupContainer extends JaidahToolTipPopupContainer {
    static propTypes = {
        ...this.propTypes,
        skipUnfreeze: PropTypes.bool
    };

    static defaultProps = {
        ...this.defaultProps,
        skipUnfreeze: false
    };

    containerProps() {
        const {
            isForceVisible,
            hideTooltip,
            skipUnfreeze
        } = this.props;

        return {
            ...super.containerProps(),
            isForceVisible,
            hideTooltip,
            skipUnfreeze
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ToolTipPopupContainer);
