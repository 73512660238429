/* eslint-disable max-lines, react/jsx-no-bind, @scandipwa/scandipwa-guidelines/jsx-no-conditional */
import CompareIcon from 'Component/CompareIcon';
import ExpandableContent from 'Component/ExpandableContent';
import {
    ACCESSORIES_STEP,
    INSURANCE_STEP,
    SERVICE_PACKAGES_STEP,
    STEPS_MAP,
    STEPS_ORDER,
    TRIM_STEP
} from 'Component/ProductActions/ProductActions.config';
import ProductStepsTab from 'Component/ProductStepsTab';
import ProgressBar from 'Component/ProgressBar';
import RelatedProductTotals from 'Component/RelatedProductTotals';
import Swatch from 'Component/Swatch';
import {
    ProductStepsComponent as JaidahProductStepsComponent
} from 'JaidahComponent/ProductSteps/ProductSteps.component';
import {
    ACCESSORIES,
    SERVICE_PACKAGES
} from 'Store/LinkedProducts/LinkedProducts.dispatcher';
import { INSURANCE } from 'Store/LinkedProducts/LinkedProducts.reducer';
import { formatPrice } from 'Util/Price';
import { getMinimumConfigurableAttrPrice, removeColorCode } from 'Util/Product';

import './ProductSteps.geely.style';

/** @namespace Geely/Component/ProductSteps/Component */
export class ProductStepsComponent extends JaidahProductStepsComponent {
    stepsRenderMap = {
        [TRIM_STEP]: this.renderTrims.bind(this),
        [ACCESSORIES_STEP]: this.renderRelatedProductTotals.bind(this, ACCESSORIES),
        [INSURANCE_STEP]: this.renderRelatedProductTotals.bind(this, INSURANCE),
        [SERVICE_PACKAGES_STEP]: this.renderRelatedProductTotals.bind(this, SERVICE_PACKAGES)
    };

    renderRelatedProductTotals(productType) {
        const {
            isMobile,
            productPrice: {
                originalPrice: {
                    minFinalPrice
                }
            }
        } = this.props;

        return (
            <RelatedProductTotals
              key="0"
              isInsurance={ productType === INSURANCE }
              isMobile={ isMobile }
              vehiclePrice={ minFinalPrice }
              productType={ productType }
            />
        );
    }

    renderAttributeValuePrice(attribute_code, attribute_value, isStarting, currentStepNo = null) {
        const { variants, parameters, currentStepNumber } = this.props;
        const { value, currency } = getMinimumConfigurableAttrPrice(
            attribute_code,
            attribute_value,
            variants,
            parameters,
            currentStepNo || currentStepNumber
        );

        if (!value) {
            return null;
        }

        const formattedPrice = formatPrice(value, currency);
        const content = isStarting
            ? __('Starting from %s', formattedPrice)
            : formattedPrice;

        return (
            <div block="ConfigurableSelectPrice">
                { content }
            </div>
        );
    }

    renderTrimType(attribute, stepNo = null) {
        const { activeProduct: { variants = [] } } = this.props;
        const {
            attribute_code = '',
            attribute_label,
            attribute_values = []
        } = attribute || {};

        const enabledAttributeValues = variants.map((variant) => variant?.attributes[attribute_code]?.attribute_value);

        const filteredAttributeValues = attribute_values.filter(
            (attrVal) => enabledAttributeValues.includes(attrVal)
        );

        const filteredAttibute = {
            ...attribute,
            attribute_values: filteredAttributeValues,
            values: filteredAttributeValues.map((val) => ({ value_index: val }))
        };

        return (
            <ExpandableContent
              key={ attribute_code }
              heading={ attribute_label }
              mix={ { block: 'ProductSteps', elem: 'Section' } }
              isArrow
              isContentExpanded
            >
                { this.renderSelectType(filteredAttibute, true, true, true, stepNo) }
                { this.renderCompareTrimsButton() }
            </ExpandableContent>
        );
    }

    renderSelectButton(option = {}, attribute_code, hasPrice = false, isStarting = false, stepNo = null) {
        const {
            updateConfigurableVariant,
            parameters = {}
        } = this.props;
        const { label = '', value = '' } = option;
        const attributeValuePrice = hasPrice
            ? this.renderAttributeValuePrice(attribute_code, value, isStarting, stepNo)
            : false;

        if (attributeValuePrice) {
            return (
                <button
                  key={ value || attribute_code }
                  onClick={ () => updateConfigurableVariant(attribute_code, value) }
                  block="ProductStepsTab"
                  elem="Select"
                  mods={ { isActive: parameters?.[attribute_code] === value } }
                  mix={ { block: 'Button', mods: { isHollow: true } } }
                >
                    { label }
                </button>
            );
        }

        return null;
    }

    renderCompareTrimsButton() {
        const { toggleCompareTrims } = this.props;

        return (
            <button
              block="ProductSteps"
              elem="Compare"
              onClick={ () => toggleCompareTrims(true) }
              mix={ { block: 'Button', mods: { likeLink: true } } }
            >
                <CompareIcon />
                <span block="ProductSteps" elem="CompareButtonText">
                    { __('Compare Trims') }
                </span>
            </button>
        );
    }

    /**
     * Render 4 hyundai steps in one screen for geely
     */
    renderTrims(attributesArray) {
        const [
            { attr: attrTrim },
            { attr: attrEngine },
            ...colorAttributesArray
        ] = attributesArray;

        const {
            parentInStock
        } = this.props;

        return (
            <>
            { this.renderTrimType(attrTrim, 1) }
            { this.renderSelectType(attrEngine, true, false, !parentInStock, 2) }
            { this.renderColorTab(colorAttributesArray) }
            </>
        );
    }

    /**
     * Overridden to remove tabs logic for mobiles
     */
    renderColorTab(attributesArray) {
        if (!attributesArray || attributesArray.length === 0) {
            return null;
        }

        return attributesArray.map((attribute) => this.renderSwatchInLeftMenu(attribute));
    }

    renderSwatchInLeftMenu(attribute = {}) {
        const {
            attr: {
                attribute_code = '',
                attribute_label = ''
            } = {},
            attr = {},
            code
        } = attribute;
        const {
            parameters: {
                [code]: selectedParameter = ''
            } = {},
            configurable_options: {
                [code]: {
                    attribute_options = {}
                }
            }
        } = this.props;
        const {
            [selectedParameter]: {
                label: selectedValue = ''
            } = {}
        } = attribute_options;

        if (!selectedValue) {
            return null;
        }

        return (
            <div block="ProductSteps" elem="Section">
                <Swatch
                  key={ attribute_code }
                  label={ attribute_label }
                  selectedValue={ removeColorCode(selectedValue) }
                >
                    { this.renderSwatch(attr) }
                </Swatch>
            </div>
        );
    }

    renderSelectType(attribute, hasPrice = false, isStarting, showOutOfStock = false, stepNo = null) {
        const {
            attribute_options = {},
            attribute_code = '',
            attribute_label = '',
            attribute_values = [],
            values = []
        } = attribute || {};

        if (showOutOfStock) {
            return (
                <div block="ProductStepsTab" elem="SelectWrapper">
                    { values.map(
                        ({ value_index }) => this.renderSelectButton(
                            attribute_options[value_index],
                            attribute_code,
                            hasPrice,
                            isStarting,
                            stepNo
                        )
                    ) }
                </div>
            );
        }

        return (
            <ExpandableContent
              key={ attribute_code }
              heading={ attribute_label }
              mix={ { block: 'ProductSteps', elem: 'Section' } }
              isContentExpanded
              isArrow
            >
                <div block="ProductStepsTab" elem="SelectWrapper">
                    { attribute_values.map(
                        (option) => this.renderSelectButton(
                            attribute_options[option],
                            attribute_code,
                            hasPrice,
                            isStarting,
                            stepNo
                        )
                    ) }
                </div>
            </ExpandableContent>
        );
    }

    renderProgressBar() {
        const { currentStepNumber } = this.props;
        const { label } = STEPS_MAP[STEPS_ORDER[currentStepNumber - 1]];

        return (
            <ProgressBar
              currentStep={ currentStepNumber }
              numberOfSteps={ STEPS_ORDER.length - 1 }
              label={ typeof label === 'function' ? label() : label }
            />
        );
    }

    /**
     * Overridden to remove tabs and render just one active tab
     */
    renderTabs() {
        const {
            configurable_options = {},
            parameters = {},
            changeStep,
            currentStepNumber,
            activeProduct,
            isMobile,
            inStock,
            handleBackMobile,
            isHeaderOnly = false,
            isChildrenOnly = false
        } = this.props;

        if (Object.keys(configurable_options).length === 0) {
            return null;
        }

        const step = STEPS_ORDER[currentStepNumber - 1];

        if (!STEPS_MAP[step].isLoadedInLeftMenu) {
            return null;
        }

        const { attribute_codes, number: stepNumber, label } = STEPS_MAP[step];

        const attributesArray = attribute_codes.map(({ code, label }) => {
            const { [code]: attr } = configurable_options;

            return {
                code,
                attr,
                label: typeof label === 'function' ? label() : label
            };
        });

        const selectedValueArray = attributesArray.map((attribute) => (
            attribute.attr?.attribute_values?.includes(parameters?.[attribute.code])
                ? {
                    value: attribute.attr.attribute_options?.[parameters?.[attribute.code]]?.label,
                    label: attribute.label,
                    code: attribute.code
                } : { value: '', label: '', code: '' }));

        const isBehind = stepNumber < currentStepNumber;
        const isNextStep = currentStepNumber - stepNumber === -1;

        const desktopTabToggleHandler = () => (
            isBehind || (isNextStep && inStock) ? changeStep(stepNumber) : null
        );

        const hasPopUp = !!STEPS_MAP[step].popUp;
        const { [step]: renderStep = () => null } = this.stepsRenderMap;
        const stepsLabel = typeof label === 'function' ? label() : label;

        return (
            <ProductStepsTab
              activeProduct={ activeProduct }
              key={ stepNumber }
              attribute_label={ `${stepNumber}. ${stepsLabel}` }
              active={ stepNumber === currentStepNumber }
              selectedValueArray={ selectedValueArray }
              handleOnClick={ desktopTabToggleHandler }
              handleMobileBack={ handleBackMobile }
              isBehind={ isBehind }
              isNextStep={ isNextStep }
              isMobile={ isMobile }
              isPopUpOnMobile={ hasPopUp }
              inStock={ inStock }
              popUpAttributes={ hasPopUp ? STEPS_MAP[step].popUp : {} }
              stepCode={ step }
              isChildrenOnly={ isChildrenOnly }
              isHeaderOnly={ isHeaderOnly }
            >
                { renderStep(attributesArray) }
            </ProductStepsTab>
        );
    }

    /**
     * Overridden to add progress bar for desktop view
     */
    render() {
        const { isReady, mix, isMobile } = this.props;

        return (
            <div
              block="ProductConfigurableAttributes"
              elem="Wrapper"
              mods={ { isLoading: !isReady } }
              mix={ mix }
            >
                { !isMobile && this.renderProgressBar() }
                { isReady ? this.renderTabs() : this.renderPlaceholders() }
                { this.renderCompareTrims() }
            </div>
        );
    }
}

export default ProductStepsComponent;
