import { connect } from 'react-redux';

import { STEPS_ORDER, SUMMARY_STEP } from 'Component/ProductActions/ProductActions.config';
import { PRODUCT_ATTS_POPUP_ID } from 'Component/ProductAttributes/ProductAttributes.config';
import {
    mapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    ProductMonthlyPriceContainer as SourceProductMonthlyPrice
}
from 'JaidahComponent/ProductMonthlyPrice/ProductMonthlyPrice.container';

/** @namespace Geely/Component/ProductMonthlyPrice/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    isMobile: state.ConfigReducer.device.isMobile,
    activeOverlay: state.OverlayReducer.activeOverlay,
    currentStepNumber: state.ProductStepReducer.currentStepNumber
});

/** @namespace Geely/Component/ProductMonthlyPrice/Container */
export class ProductMonthlyPriceContainer extends SourceProductMonthlyPrice {
    containerProps() {
        const {
            isMobile,
            activeOverlay,
            currentStepNumber,
            popupOpenDirection,
            isMyVehicles = false
        } = this.props;

        const currentStep = STEPS_ORDER[currentStepNumber - 1];
        const isSummaryStep = currentStep === SUMMARY_STEP;

        return {
            ...super.containerProps(),
            isMobile,
            isAttributePopupOpen: activeOverlay === PRODUCT_ATTS_POPUP_ID,
            isSummaryStep,
            popupOpenDirection,
            isMyVehicles
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductMonthlyPriceContainer);
